import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons"

const HeroBlock = ({
  block,
  forceLoadImages = false,
  firstComponent = false,
}) => {
  const secondImage = block.secondary_image?.filename?.length > 0
  const accentColor =
    block.accent_color?.length > 0 ? block.accent_color : "bg-primary"

  return (
    <BlockWrapper
      block={block}
      showHeadline="false"
      blockPadding={`${firstComponent ? "pt-0" : "pt-block"} pb-block`}
    >
      <div
        className={`flex flex-row flex-wrap ${
          block.alternate_design ? "-mx-half-grid" : "-mx-grid"
        } `}
      >
        {block.alternate_design ? (
          <>
            <div className="hidden w-1/4 lg:block px-half-grid">
              <div className={`flex w-full h-full ${accentColor}`}></div>
            </div>
            <div
              className={`${
                secondImage ? "w-2/3 lg:w-2/4" : "w-full lg:w-3/4"
              } px-half-grid`}
            >
              {block.image?.filename?.length > 0 && (
                <Image
                  className={`object-cover w-full h-auto ${
                    secondImage ? "aspect-[4/3]" : "aspect-video"
                  }`}
                  image={block.image}
                  adaptToPixelRatio={true}
                  forceLoad={forceLoadImages}
                />
              )}
            </div>
            {secondImage && (
              <div className="w-1/3 lg:w-1/4 px-half-grid">
                <Image
                  className="object-cover w-full h-full"
                  image={block.secondary_image}
                  adaptToPixelRatio={true}
                  forceLoad={forceLoadImages}
                />
              </div>
            )}
          </>
        ) : (
          <div className="w-full px-grid">
            <Image
              className="object-cover w-full h-auto aspect-[16/7]"
              image={block.image}
              adaptToPixelRatio={true}
              forceLoad={forceLoadImages}
            />
          </div>
        )}
        {block.alternate_design && (
          <div className="hidden w-1/4 lg:block px-half-grid"></div>
        )}

        <div
          className={`w-full ${
            block.alternate_design
              ? "lg:w-3/4 px-half-grid"
              : " lg:w-1/2 px-grid"
          } pt-10`}
        >
          <HeadlineEnhanced
            className=""
            kicker={block.kicker}
            headlineLevel={firstComponent ? "h1" : "h2"}
            headline={block.headline}
            headlineClass=""
          />
        </div>

        {block.alternate_design && (
          <div className="hidden w-1/4 lg:block px-grid"></div>
        )}
        <div
          className={`w-full ${
            block.alternate_design
              ? "lg:w-3/4 px-half-grid"
              : "lg:w-1/2 px-grid"
          } pt-10 `}
        >
          {block.alternate_design === false && (
            <div className="hidden w-48 h-1 mb-12 lg:block bg-primary"></div>
          )}
          <Richtext text={block.text} />
          {block.link_text?.length > 0 && (
            <Link className="mt-10 btn btn-primary" link={block.link}>
              {block.link_text}
              <FontAwesomeIcon
                icon={faArrowRight}
                size="1x"
                className="inline-block ml-2.5 md:-mr-1.5"
              />
            </Link>
          )}
        </div>
      </div>
    </BlockWrapper>
  )
}

export default HeroBlock
